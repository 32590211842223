var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "body-container gap-4" },
    [
      _c("AccountInfo"),
      _c("WorkInfo"),
      _c("Finance", { attrs: { user: _vm.user } }),
      _c(
        "Card",
        [
          _c("h3", { staticClass: "text-black ont-bold text-lg" }, [
            _vm._v("Security"),
          ]),
          _c("ChangePassword"),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }