<template>
  <div class="body-container gap-4">
    <AccountInfo />
    <WorkInfo />
    <Finance :user="user" />
    <!-- privacy and security -->
    <Card>
      <h3 class="text-black ont-bold text-lg">Security</h3>
      <ChangePassword />
    </Card>
  </div>
</template>
<script>
  import { mapState } from "vuex";
  import Hr from "@/UI/Hr";
  import { Button } from "@/UI/Button";
  export default {
    components: {
      Hr,
      Button,
      AccountInfo: () => import("./AccountInfo"),
      WorkInfo: () => import("./WorkInfo"),
      Finance: () => import("./Finance"),
      ChangePassword: () => import("./ChangePassword"),
    },
    data: () => ({
      settingType: "personal",
      showCard: false,
      source: "",
      profilePicture: "",
      showProfileImage: false,
    }),
    computed: {
      ...mapState({
        user: (state) => state?.auth?.user,
      }),
    },
  };
</script>
<style scoped>
  @import "~@/assets/styles/animation.css";
  .body-container {
    display: grid;
    grid-template-columns: 100%;
    /* min-height: calc(100vh - 12rem); */
    /* grid-gap: 10px; */
  }
  .card-body {
    min-height: calc(100vh - 24rem);
  }
  @media (max-width: 768px) {
    .body-container {
      grid-template-columns: 100%;
    }
    .card-body {
      min-height: 100%;
    }
    .nav {
      /* width: 15rem; */
      overflow-x: auto;
    }
    .nav-heading {
      min-width: 20rem;
      /* overflow-x: auto; */
    }
    .nav-overflow::-webkit-scrollbar {
      display: none;
    }
  }
  .personal {
    font-weight: 600;
  }
  .personal:after {
    /* content: ""; */
    position: absolute;
    content: "";
    height: 3px;
    border-radius: 5px;
    bottom: -0.65rem;
    margin: 0 auto;
    left: 0;
    /* right: 0; */
    width: 100%;
    background: #4d2ba1;

    /* optional animation */
    -o-transition: 0.5s;
    -ms-transition: 0.5s;
    -moz-transition: 0.5s;
    -webkit-transition: 0.5s;
    transition: 0.5s;
  }
</style>
